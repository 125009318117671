@import './Button/button.css';
@import './Typography.css';
@import './Card.css';
@import './Toggle.css';
@import './reset.css';
@import './Fonts.css';

:root {
	--color: rgb(41, 117, 209);
	--darkColor: rgb(24, 69, 123);
	--textColor: rgb(24, 69, 123);
}
@media (min-width: 400px) {
	:focus:not(.focus-visible) {
		outline: none;
	}
	:focus-visible:not(:active):not(input) {
		outline: 3px solid var(--lightColor);
	}
}

:active {
	outline: none;
}

html,
body,
#js {
	min-height: 100%;
}

html:fullscreen {
	background-color: white;
	overflow: auto !important;
}
body {
	overflow-x: hidden !important;
}

button {
	border: none;
	border-radius: 0;
}

blockquote {
	background: var(--lighterColor);
	border-radius: 0.3rem;
	padding: 1rem;
	padding-bottom: 0.6rem;
	margin: 1rem 0;
	color: var(--darkerColor);
}

.ui__.answer-group {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
@media (min-width: 500px) {
	.ui__.answer-group > :not(:last-child) {
		margin-right: 1rem;
	}
}
@media (max-width: 500px) {
	.ui__.answer-group {
		flex-wrap: wrap;
	}
	.ui__.answer-group > * {
		flex: 1;
		white-space: nowrap;
		margin: 0.5rem !important;
	}
}

.ui__.light-bg {
	background-color: var(--lighterColor) !important;
}
.ui__.lighter-bg {
	background-color: var(--lightestColor) !important;
}

.ui__.dark-bg {
	background-color: #3b3945;
}

.ui__.dark-bg * {
	color: #f3f3f3;
}

.ui__.light-border {
	border: 4px solid var(--lighterColor);
	border-radius: 0.3rem;
}

.ui__.center-flex {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.ui__.hide {
	display: none;
}
@media (max-width: 850px) {
	.ui__.hide-mobile {
		display: none;
	}
}

section {
	padding: 1rem 0;
}

.ui__.container {
	width: 100%;
	max-width: calc(
		14rem + 1rem + 850px
	); /* accomodate for the left navigation bar and margin on desktop */
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.6rem;
	padding-left: 0.6rem;
}
.ui__.container .ui__.full-width {
	--margin: calc((850px - 100vw - 0.6rem) / 2);
	margin-right: var(--margin);
	margin-left: var(--margin);
}
@media (max-width: 850px) {
	.ui__.container .ui__.full-width {
		margin: 0 -0.6rem;
	}
}

ul.ui__.no-bullet {
	list-style: none;
}
span.ui__.enumeration:not(:last-of-type)::after {
	content: '·';
	margin: 0 0.3rem;
	display: inline-block;
}

.ui__.label {
	font-size: 85%;
	line-height: initial;
	padding: 0.4rem 0.6rem;
	font-weight: bold;
	color: white !important;
	background: var(--darkColor);
	border-radius: 0.3rem;
	text-align: center;
}
.ui__.small.label {
	font-size: 75%;
	padding: 0.2rem 0.4rem;
}

.no-scroll {
	overflow: hidden;
}

.markdown table {
	text-align: left;
	margin-bottom: 1rem;
	width: 100%;
}

.markdown table tbody tr:nth-child(2n + 1) {
	background-color: var(--lighterColor);
}

.markdown table td,
.markdown table th {
	padding: 5px 10px;
}

*:not(pre) > code {
	font-family: monospace;
	margin: 0 0.2rem;
	padding: 0 0.2rem;
	box-shadow: 0px 1px 1px 1px #d9d9d9, 0 0 0 1px #d9d9d9;
	border-radius: 0.2rem;
}

pre.ui__.code {
	color: rgb(197, 200, 198);
	text-shadow: rgba(0, 0, 0, 0.3) 0px 1px;
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	tab-size: 4;
	hyphens: none;
	padding: 1em;
	margin: 0.5em 0px;
	overflow: auto;
	border-radius: 0.3em;
	background: rgb(29, 31, 33);
}

pre.ui__.code > code {
	color: rgb(197, 200, 198);
	text-shadow: rgba(0, 0, 0, 0.3) 0px 1px;
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	tab-size: 4;
	hyphens: none;
}

input.ui__,
textarea.ui__,
select.ui__ {
	padding: 0.4rem;
	max-width: 100%;
	margin-bottom: 0.6rem;
	border: 1px solid var(--lighterTextColor);
	border-radius: 0.3rem;
	background-color: white;
	color: inherit;
	font-size: inherit;
	transition: border-color 0.1s;
	position: relative;
	font-family: inherit;
}
input.ui__ {
	width: 25rem;
}
textarea.ui__ {
	width: 100%;
}

input.ui__[inputmode='numeric'] {
	width: 10rem;
}
input.ui__[type='date'] {
	width: auto;
}
input.ui__.suffixed {
	text-align: right;
}
input.ui__:focus,
select.ui__:focus {
	outline: 1px solid var(--color);
}

/* Remove IE's clear button the appears before our suffix */
input.ui__::-ms-clear {
	width: 0;
	height: 0;
}

input.ui__::placeholder {
	opacity: 0.6;
	color: grey;
}

select.ui__:invalid {
	opacity: 0.6;
	color: grey;
	border-color: black;
}

img[src="http://twemoji.maxcdn.com/2/72x72/.png"]
{
	display: none;
}

details > summary {
	cursor: pointer;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap; /* added line */
	border: 0;
}
