html {
	transition: none !important;
	line-height: 1.7rem;
}
@media (max-width: 500px) {
	html {
		font-size: 0.9em;
	}
}
@media (min-width: 500px) and (max-width: 1920px) {
	html {
		font-size: 1.1em;
	}
}
@media (min-width: 1920px) {
	html {
		font-size: 1.16em;
	}
}

@media print {
	html {
		font-size: 1em;
	}
}

body {
	font-weight: 400;
	color: #040e19;
	color: var(--darkColor);
	font-family: Arial, Helvetica, sans-serif;
}

h1 {
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: 1.9rem;
	line-height: 2rem;
}
h2 {
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: 1.4rem;
}
h3 {
	margin-top: 1rem;
	margin-bottom: 0.6rem;
	font-size: 1.2rem;
}
h4 {
	margin-top: 0.6rem;
	margin-bottom: 0.4rem;
	font-size: 1rem;
}
h5 {
	margin-top: 0.4rem;
	margin-bottom: 0.2rem;
	font-size: 0.9rem;
}
h6 {
	margin-top: 0.4rem;
	margin-bottom: 0.2rem;
	font-size: 0.9rem;
	color: var(--lighterTextColor);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--darkColor);
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 600;
	scroll-margin-top: 1rem; /* Add a margin for anchor links */
}

button {
	font-weight: normal;
}
p,
ul {
	margin: 0 0 0.4rem;
}

p.ui__.lead {
	font-size: 120%;
	line-height: 2rem;
	color: var(--darkColor);
}
ul {
	list-style: '› ';
	padding-left: 0.8rem;
}
ol {
	margin: 0;
	padding-left: 1.1rem;
}
li {
	padding: 0.2em 0;
}
a {
	border: none;
	font-size: inherit;
	padding: none;
	text-decoration: underline;
	text-underline-offset: 4px;
	color: rgb(41, 117, 209);
	color: var(--color);
}

p img {
	max-width: 100%;
}

a:not(:disabled):not(.button):not(.button-choice):hover {
	color: var(--darkColor);
}

strong,
b {
	font-weight: bold;
}

textarea {
	font-family: inherit;
}

small,
.ui__.notice {
	color: rgba(0, 0, 0, 0.6);
	color: var(--lighterTextColor);
	font-size: 85%;
	line-height: 1.5rem;
}

small {
	display: inline-block;
}
